@import "./../../styles/colors";

.loading {
    background-color: $loading--cover-background-color;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: $site--font-color-alt;

    .loading__inner {
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        text-align: center;
        width: 80px;
    }
}