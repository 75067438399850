@import './../../styles/colors';

.tab-group {
    &__clickable {
        border: 1px solid $tab-group--clickable-color-border;
        border-bottom: none;
        background-color: $tab-group--clickable-background-color;
        margin: 0;

        &:not(:first-of-type) {
            border-left: none;
        }

        &:hover {
            background-color: $tab-group--clickable-hover-color !important;
        }

        &--selected {
            background-color: $tab-group--clickable-selected-color;
            cursor: default;

            &:hover {
                background-color: $tab-group--clickable-selected-color !important;
            }
        }
    }

    &__content {
        border: 1px solid $tab-group--content-color-border;
        padding: 8px 16px;
        background-color: $tab-group--content-color-background;
    }
}