@import "./../../styles/sizes";

.hero-group {
    margin: 16px 0;

    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    &__top-left {
        display: flex;
        align-items: center;
        flex-direction: column;

        .hero-group__count-tick-container {
            display: none;
        }
    }

    &__top-right {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        width: 100%;
        justify-content: space-between;
    }

    &__count-tick-container {
        display: flex;
        align-items: center;
    }

    &__heading {
        display: inline-block;
        margin-right: 12px;
    }

    &__counter {
        margin-right: 16px;
    }
}

@media (min-width: hero-grid-width(3, "small")) {
    .hero-group {
        &__top {
            flex-direction: row;
            align-items: center;
        }

        &__top-left {
            flex-direction: row;
            margin-bottom: 8px;
            margin-right: 32px;

            .hero-group__count-tick-container {
                display: flex;
            }
        }

        &__top-right {
            width: auto;
            justify-content: normal;

            .hero-group__count-tick-container {
                display: none;
            }
        }
    }
}