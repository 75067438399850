@import "./../../styles/colors";
@import "./../../styles/breakpoints";
@import "./../../styles/sizes";

.checklist__hero-group {
    width: 50%;
    display: inline-block;
    padding: 4px 8px;
    vertical-align: top;

    .checklist__hero-group-cell {
        background-color: $checklist--unobtained-color;

        &.checklist__hero-group-cell--obtained {
            background-color: $checklist--obtained-color;
        }
    }

    table {
        border-color: $checklist--table-border-color;

        tr td {
            border-color: $checklist--table-border-color;
        }
    }
}

@media (min-width: hero-grid-width(3, "medium")) {
    .checklist__hero-group {
        &:not(.checklist__hero-group--side) h4 {
            min-height: 40px;
        }

        &:not(.checklist__hero-group--side) {
            width: 33%;
        }
    }
}

@media (min-width: hero-grid-width(4, "medium")) {
    .checklist__hero-group--side {
        width: 100%;
    }
}

@media (min-width: hero-grid-width(5, "medium")) {
    .checklist__hero-group {
        &:not(.checklist__hero-group--side) {
            width: 25%;

            &.checklist__hero-group--base {
                width: 20%;
            }
        }
    }
}