@import "./../../styles/colors";
@import "./../../styles/sizes";

.feat-tile {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: $feat--tile-background-color-unobtained;
    height: 100px;
    margin: 8px;

    &__top-half {
        display: inline-block;
        height: 100%;
        width: 30%;
        background-color: $site--main-color-dark;
        text-align: center;

        img {
            height: 80%;
            margin-top: 8px;
        }
    }

    &__bottom-half {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 70%;
        padding: 4px 8px;
        vertical-align: top;

        b,
        p {
            opacity: 0.5;
        }

        p {
            margin-block-start: 0.5em;
            margin-block-end: 0.5em;
        }
    }

    &__progress-bar-inner {
        background-color: $progress-bar--main-color;
        height: 100%;
    }

    &__progress-bar-outer {
        width: calc(100% - 16px);
        height: 8px;
        background-color: $progress-bar--background-color;
        margin: 0 8px;
        opacity: 60%;
    }

    &--obtained {
        .feat-tile__top-half {
            background-color: $feat--tile-background-color-obtained;
        }

        .feat-tile__bottom-half {

            b,
            p {
                opacity: 1;
            }
        }
    }
}

@media (min-width: hero-grid-width(3, "medium")) {
    .feat-tile {
        &__bottom-half {
            p {
                margin-block-start: 1em;
                margin-block-end: 1em;
            }
        }
    }
}

@media (min-width: hero-grid-width(4, "medium")) {
    .feat-tile {
        width: 180px;
        height: 280px;

        &__top-half {
            height: 130px;
            width: 100%;

            img {
                margin-top: 7%;
            }
        }

        &__bottom-half {
            height: 150px;
            width: 100%;
            padding: 8px;
        }
    }
}