@import "./../../styles/colors";

.progress-bar {
    &__outer {
        width: 100%;
        height: 32px;
        background-color: $progress-bar--background-color;
    }

    &__inner {
        background-color: $progress-bar--main-color;
        height: 100%;
        width: 0%;
        transition: width 0.4s linear;
    }

    &__text-container {
        position: relative;
        top: -29px;
        display: flex;
        justify-content: center;
    }

    &__text {
        color: $site--font-color-alt;
    }
}