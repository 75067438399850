@import "./../../styles/colors";

.modal__cover {
    display: inline-block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $loading--cover-background-color;
}

.modal__content {
    display: block;
    border: 1px solid black;
    width: 80%;
    height: 60%;
    left: 10%;
    top: 20%;
    margin: 0;
    background-color: $site--main-color-light;
}