@import "./../../styles/breakpoints";
@import "./../../styles/colors";
@import "./../../styles/sizes";

.hero-grid {
    display: grid;
    gap: $hero-grid--grid-gap-size;
    grid-template-columns: repeat(1, 1fr);

    &__item {
        background-color: $hero-grid--item-background-color;
        max-width: $hero-grid--small-width;

        .hero-grid__image-area > img {
            transition: opacity 0.3s;
        }
    }

    &__item--faded .hero-grid__image-area > img {
        opacity: 60%;
    }

    &__info {
        text-align: center;
        background-color: $site--main-color-dark;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    &__name {
        text-decoration: none;
        color: $site--link-color-alt
    }

    &__title {
        color: $site--font-color-alt;
        font-size: small;
    }

    &__info {
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__image-area {
        text-align: center;
        height: $hero-grid--small-height;
        max-width: $hero-grid--small-width;
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__image-area {
        .hero-grid__thumbnail {
            height: 100%;
            width: 100%;
        }
    }

    &__icon-area {
        height: 95%;
        width: 95%;
        position: relative;
        top: -100%;
        left: 0%;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width: hero-grid-width(2, "small")) {
    .hero-grid {
        grid-template-columns: repeat(2, 1fr);
        max-width: hero-grid-width(2, "small") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(3, "small")) {
    .hero-grid {
        grid-template-columns: repeat(3, 1fr);
        max-width: hero-grid-width(3, "small") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(3, "medium")) {
    .hero-grid {
        grid-template-columns: repeat(3, 1fr);
        max-width: hero-grid-width(3, "medium") - ($site--main-padding-horizontal * 2);

        &__item {
            max-width: $hero-grid--medium-width;
        }

        &__image-area {
            height: $hero-grid--medium-height;
            max-width: $hero-grid--medium-width;
        }
    }
}

@media (min-width: hero-grid-width(4, "medium")) {
    .hero-grid {
        grid-template-columns: repeat(4, 1fr);
        max-width: hero-grid-width(4, "medium") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(5, "medium")) {
    .hero-grid {
        grid-template-columns: repeat(5, 1fr);
        max-width: hero-grid-width(5, "medium") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(6, "medium")) {
    .hero-grid {
        grid-template-columns: repeat(6, 1fr);
        max-width: hero-grid-width(6, "medium") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(6, "large")) {
    .hero-grid {
        grid-template-columns: repeat(6, 1fr);
        max-width: hero-grid-width(6, "large") - ($site--main-padding-horizontal * 2);

        &__item {
            max-width: $hero-grid--large-width;
        }

        &__image-area {
            height: $hero-grid--large-height;
            max-width: $hero-grid--large-width;
        }
    }
}

@media (min-width: hero-grid-width(7, "large")) {
    .hero-grid {
        grid-template-columns: repeat(7, 1fr);
        max-width: hero-grid-width(7, "large") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(8, "large")) {
    .hero-grid {
        grid-template-columns: repeat(8, 1fr);
        max-width: hero-grid-width(8, "large") - ($site--main-padding-horizontal * 2);
    }
}

@media (min-width: hero-grid-width(9, "large")) {
    .hero-grid {
        grid-template-columns: repeat(9, 1fr);
        max-width: hero-grid-width(9, "large") - ($site--main-padding-horizontal * 2);
    }
}