@import "./../../styles/breakpoints";
@import "./../../styles/colors";

.manage-tile {
    width: calc(100% - 32px);
    display: inline-block;
    text-align: center;
    height: 160px;
    background-color: $site--main-color-dark;
    margin: 16px;
    text-decoration: none;
    color: $site--link-color-alt;
    line-height: 160px;
}

@media #{$breakpoint--large} {
    .manage-tile {
        width: calc(50% - 32px);
    }
}