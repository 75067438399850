@import "./../../styles/colors";
@import "./../../styles/breakpoints";

.data-field {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;

    border-bottom: 1px solid $site--main-color-dark;
    width: 100%;
    margin: 0 4px 8px 4px;
    padding-bottom: 8px;

    label {
        font-weight: bold;
        padding-bottom: 4px;
    }
}

@media #{$breakpoint--small} {
    .data-field {
        flex-direction: row;
        padding-bottom: 2px;

        label {
            padding-bottom: 0;
        }
    }
}

@media #{$breakpoint--medium} {
    .data-field {
        width: calc(50% - 8px);
    }
}