$spinner-time: 3s;
$orb-count: 5;
$spinner-size: 60px;
$orb-size: 12px;

// https://loading.io/css/
.lds-roller,
.lds-roller div,
.lds-roller div:after {
    box-sizing: border-box;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: $spinner-size;
    height: $spinner-size;
    margin-bottom: 16px;
}

.lds-roller div {
    animation: lds-roller $spinner-time linear infinite;
    transform-origin: $spinner-size / 2 $spinner-size / 2;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: $orb-size;
    height: $orb-size;
    border-radius: 50%;
    background: currentColor;
}

.lds-roller div:nth-child(1) {
    animation-delay: ($spinner-time / $orb-count) * -1 * 1;
}

.lds-roller div:nth-child(2) {
    animation-delay: ($spinner-time / $orb-count) * -1 * 2;
}

.lds-roller div:nth-child(3) {
    animation-delay: ($spinner-time / $orb-count) * -1 * 3;
}

.lds-roller div:nth-child(4) {
    animation-delay: ($spinner-time / $orb-count) * -1 * 4;
}

.lds-roller div:nth-child(5) {
    animation-delay: ($spinner-time / $orb-count) * -1 * 5;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}