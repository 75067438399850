// Tan
// $site--main-color-light: #E9CC9C;
// $site--main-color-dark: #725c36;
// $site--main-color-dark-accent: #8f703b;

// $site--font-color: #191207;
// $site--font-color-alt: white;
// $site--link-color: #387aff;
// $site--link-color-alt: #75e1ff;
// $site--link-color-navigation: #00c8ff;
// $site--button-color-background: #b89b6d;
// $site--button-color-hover: darken($site--button-color-background, 12%);
// $site--button-color-border: #725c36;
// $site--button-color-disabled: #bbbbbb;
// $site--table-color-background: #9e8252;
// $site--table-color-border: #cccccc;
// $site--input-color-background: white;

// $hero-grid--item-background-color: $site--table-color-background;

// $progress-bar--main-color: rgb(79, 218, 79);
// $progress-bar--background-color: $site--main-color-dark;

// $feat--tile-background-color-unobtained: $site--table-color-background;
// $feat--tile-background-color-obtained: rgb(133, 255, 204);

// $checklist--table-border-color: #cccccc;
// $checklist--unobtained-color: #c4aa80;
// $checklist--obtained-color: rgb(79, 218, 79);

// $tab-group--clickable-background-color: #a88a5a;
// $tab-group--clickable-hover-color: #caac7c;
// $tab-group--clickable-selected-color: #caac7c;
// $tab-group--clickable-color-border: $site--main-color-dark;
// $tab-group--content-color-background: #caac7c;
// $tab-group--content-color-border: $site--main-color-dark;

// $loading--cover-background-color: #00000055;

// Classic
$site--main-color-light: #c4bcd4;
$site--main-color-dark: #282c34;
$site--main-color-dark-accent: #282c34;

$site--font-color: $site--main-color-dark;
$site--font-color-alt: white;
$site--link-color: #1d68ff;
$site--link-color-alt: #00c8ff;
$site--link-color-navigation: #00c8ff;
$site--button-color-background: #e8dffa;
$site--button-color-hover: darken($site--button-color-background, 4%);
$site--button-color-border: #8678a3;
$site--button-color-disabled: #bbbbbb;
$site--table-color-background: #71698c;
$site--table-color-border: #a099bb;
$site--input-color-background: white;

$hero-grid--item-background-color: $site--table-color-background;

$progress-bar--main-color: rgb(6, 192, 6);
$progress-bar--background-color: $site--main-color-dark;

$feat--tile-background-color-unobtained: $site--table-color-background;
$feat--tile-background-color-obtained: rgb(133, 255, 204);

$checklist--table-border-color: #ddd;
$checklist--unobtained-color: #71698c;
$checklist--obtained-color: rgb(72, 177, 72);

$tab-group--clickable-background-color: #9e94bf;
$tab-group--clickable-hover-color: $site--table-color-background;
$tab-group--clickable-selected-color: #c3b7e7;
$tab-group--clickable-color-border: black;
$tab-group--content-color-background: #c3b7e7;
$tab-group--content-color-border: black;

$loading--cover-background-color: #00000055;

// Other
$orb-red-color: #B13E46;
$orb-blue-color: #4FABD7;
$orb-green-color: #8DC57E;
$orb-colorless-color: #c3c1d2;