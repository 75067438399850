$site--main-padding-vertical: 16px;
$site--main-padding-horizontal: 16px;

$hero-grid--ratio: 1.141078838;

$hero-grid--small-width: 160px;
$hero-grid--small-height: $hero-grid--small-width * $hero-grid--ratio;
$hero-grid--medium-width: 200px;
$hero-grid--medium-height: $hero-grid--medium-width * $hero-grid--ratio;
$hero-grid--large-width: 240px;
$hero-grid--large-height: $hero-grid--large-width * $hero-grid--ratio;

$hero-grid--grid-gap-size: 8px;

@function hero-grid-width($colCount, $size) {
    @if $size =="small" {
        @return ($hero-grid--small-width * $colCount)+($hero-grid--grid-gap-size * ($colCount - 1))+($site--main-padding-horizontal * 2);
    }

    @if $size =="medium" {
        @return ($hero-grid--medium-width * $colCount)+($hero-grid--grid-gap-size * ($colCount - 1))+($site--main-padding-horizontal * 2);
    }

    @return ($hero-grid--large-width * $colCount)+($hero-grid--grid-gap-size * ($colCount - 1))+($site--main-padding-horizontal * 2);
}