@import "./styles/breakpoints";
@import "./styles/colors";
@import "./styles/sizes";


// @font-face {
//   font-family: fehFont;
//   src: url(../public/fonts/Fire_Emblem_Heroes_Font.ttf);
// }

* {
  box-sizing: border-box;
}

body {
  // font-family: fehFont, 'Segoe UI';
  font-family: 'Segoe UI';
  color: $site--font-color;
  background-color: $site--main-color-dark;
}

header,
footer {
  background-color: $site--main-color-dark;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: $site--font-color-alt;
  padding: 8px 16px;
}

header {
  a {
    text-decoration: none;
    margin-right: 16px;
    color: $site--link-color-navigation;
    display: inline-block;
  }

  .heading-flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

main {
  padding: $site--main-padding-vertical $site--main-padding-horizontal;
  margin: 0;
  min-height: 60vh;
  background-color: $site--main-color-light;
  display: inline-block;
  width: 100%;
}

a {
  color: $site--link-color;
}

table {
  width: 100%;
  margin: 8px 0;
  background-color: $site--table-color-background;
  border: 1px solid $site--table-color-border;
  border-spacing: 0;
  display: inline-table;
}

th,
td {
  padding: 12px;
  text-align: center;
}

th {
  color: $site--font-color-alt;
  background-color: $site--main-color-dark;
  border-bottom: 1px solid $site--main-color-dark;
}

tr {
  td {
    border-bottom: 1px solid $site--table-color-border;

    a {
      color: $site--link-color-alt;
    }
  }

  &:last-of-type {
    td {
      border-bottom: none;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 4px 0 8px 0;
}

.hero-bio {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image-panel {
    width: 60vh;
    max-width: 100%;

    img {
      width: 100%
    }
  }

  &__info-panel {
    width: 100%;
    vertical-align: top;

    &--view {
      .data-field, .data-term {
        width: 100%;
      }

      @media (min-width: hero-grid-width(5, "medium")) {
        .data-field, .data-term {
          width: calc(50% - 8px);
        }
      }
    }

    &--manage {
      .data-field, .data-term {
        width: 100%;
      }

      @media (min-width: hero-grid-width(6, "medium")) {
        .data-field, .data-term {
          width: calc(50% - 8px);
        }
      }
    }
  }
}

a.button,
button,
input[type='button'],
input[type='submit'] {
  height: 32px;
  padding: 8px 12px;
  border: 1px solid $site--button-color-border;
  background-color: $site--button-color-background;
  cursor: pointer;
  transition: background-color 0.5s;
  text-decoration: none;
  display: inline-block;
  color: $site--font-color;
  line-height: 16px;
  margin-right: 8px;
  font-size: 13.33333px;

  &:hover:not(:disabled) {
    background-color: $site--button-color-hover;
  }

  &:disabled {
    background-color: $site--button-color-disabled;
    border-color: lighten($site--font-color, 30%);
    color: lighten($site--font-color, 30%);
    cursor: default;
  }
}

input[type="text"],
input[type="password"],
input[type="number"],
select {
  height: 24px;
  padding: 0 4px;
  border: 1px solid $site--main-color-dark;
  background-color: $site--input-color-background;
}

.weapon-icon {
  width: 32px;
}

.move-type-icon {
  width: 32px;
}

hr {
  border: none;
  border-top: 1px double $site--main-color-dark;
  width: 100%
}

.width-bounder {
  width: 100%;
  margin: auto;
}

.smallAndDown {
  display: block;

  &--table-cell {
    display: table-cell
  }
}

.smallAndUp {
  display: none;

  &--table-cell {
    display: none;
  }
}

.mediumAndUp {
  display: none;

  &--table-cell {
    display: none;
  }
}

@media #{$breakpoint--small} {
  .smallAndUp {
    display: block;

    &--table-cell {
      display: table-cell;
    }
  }
}

@media #{$breakpoint--medium} {
  .smallAndDown {
    display: none;

    &--table-cell {
      display: none;
    }
  }

  .mediumAndUp {
    display: block;

    &--table-cell {
      display: table-cell
    }
  }

  .hero-bio {
    flex-direction: row;
    align-items: flex-start;

    &__image-panel {
      display: inline-block;
      width: 35%;
    }

    &__info-panel {
      display: inline-block;
      width: 60%;
    }
  }
}

@media (min-width: hero-grid-width(2, "small")) {
  .width-bounder {
    width: hero-grid-width(2, "small") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(3, "small")) {
  .width-bounder {
    width: hero-grid-width(3, "small") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(3, "medium")) {
  .width-bounder {
    width: hero-grid-width(3, "medium") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(4, "medium")) {
  .width-bounder {
    width: hero-grid-width(4, "medium") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(5, "medium")) {
  .width-bounder {
    width: hero-grid-width(5, "medium") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(6, "medium")) {
  .width-bounder {
    width: hero-grid-width(6, "medium") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(6, "large")) {
  .width-bounder {
    width: hero-grid-width(6, "large") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(7, "large")) {
  .width-bounder {
    width: hero-grid-width(7, "large") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(8, "large")) {
  .width-bounder {
    width: hero-grid-width(8, "large") - ($site--main-padding-horizontal * 2);
  }
}

@media (min-width: hero-grid-width(9, "large")) {
  .width-bounder {
    width: hero-grid-width(9, "large") - ($site--main-padding-horizontal * 2);
  }
}