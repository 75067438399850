@import "./../../styles/breakpoints";
@import "./../../styles/sizes";

.image-pane__button-area {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .image-pane__button {
        display: none;
        margin-bottom: 4px;
    }

    .image-pane__arrow {
        width: 64px;
    }
}

@media #{$breakpoint--small} {
    .image-pane__button-area {
        .image-pane__button {
            display: initial;
            margin-bottom: 0;
        }

        .image-pane__arrow {
            display: none;
        }
    }
}

@media #{$breakpoint--medium} {
    .image-pane__button-area {
        flex-direction: column;

        .image-pane__button {
            margin-bottom: 4px;
        }
    }
}

@media (min-width: hero-grid-width(5, "medium")) {
    .image-pane__button-area {
        flex-direction: row;

        .image-pane__button {
            margin-bottom: 0;
        }
    }
}