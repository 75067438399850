@import "./../../styles/colors";
@import "./../../styles/breakpoints";

.data-term {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;

    border-bottom: 1px solid $site--main-color-dark;
    width: 100%;
    margin: 0 4px 8px 4px;
    padding-bottom: 8px;

    dt {
        font-weight: bold;
        padding-bottom: 4px;
    }

    dd {
        margin-inline-start: 20px;
        text-align: left;
    }
}

@media #{$breakpoint--small} {
    .data-term {
        flex-direction: row;
        padding-bottom: 2px;

        dt {
            padding-bottom: 0;
        }

        dd {
            margin-inline-start: 0;
            text-align: right;
        }
    }
}

@media #{$breakpoint--medium} {
    .data-term {
        width: calc(50% - 8px);
    }
}