@import "./../../styles/sizes";

.checklist__main-heroes {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.checklist__side-heroes {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

@media (min-width: hero-grid-width(4, "medium")) {
    .checklist__main-heroes {
        width: 80%;

        &--full-width {
            width: 100%;
        }
    }

    .checklist__side-heroes {
        width: 20%;
        padding: 0 8px;
        border-left: 1px solid black;
    }
}